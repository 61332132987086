import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { fr } from "date-fns/locale";
import { Avatar } from "primereact/avatar";
import { toast } from "react-toastify";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { dateHelpers } from "../../services/utils/dateHelpers";
import { updateUserById, selectUserPositions } from "../../redux/slice/UserSlice";
import { fetchTeamsList, selectTeamsList } from "../../redux/slice/teamsSlice";
import { BASE_URL } from "../../services/utils/apiRessources";
import AddLeaveForUserForm from "./AddLeaveForUserForm";
import TeamPositionDropdown from "../../components/teamPositionDropDown/TeamPositionDropdown";
import PropTypes from "prop-types";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "bootstrap/dist/css/bootstrap.css";
import "../../styles/globalStyle.sass";
import "./userCard.sass";

registerLocale("fr", fr);

const UserCard = ({ user, roles, onUserUpdate }) => {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeamsList);
  const positions = user.positions;
  const [birthdayDate, setBirthdayDate] = useState(new Date(user.birthdayDate));
  const [hiringDate, setHiringDate] = useState(new Date(user.hiringDate));
  const [disabled, setDisabled] = useState(true);
  const [userData, setUserData] = useState({
    leaveBalance: "",
    birthdayDate: "",
    hiringDate: "",
    lastMonthLeaveBalance: "",
    cellPhone: "",
  });
  const [selections, setSelections] = useState([{ team: "", position: "" }]);

  useEffect(() => {
    dispatch(fetchTeamsList());
    setSelections(
      positions.map((positionItem) => ({
        team: positionItem.team.id,
        position: positionItem.id,
      }))
    );
    setUserData({
      leaveBalance: user.leaveBalance,
      birthdayDate: new Date(user.birthdayDate),
      hiringDate: new Date(user.hiringDate),
      lastMonthLeaveBalance: user.lastMonthLeaveBalance,
      cellPhone: user.cellPhone,
    });
    return () => {
      setUserData({
        leaveBalance: "",
        birthdayDate: "",
        hiringDate: "",
        lastMonthLeaveBalance: "",
        cellPhone: "",
      });
    };
  }, [user, dispatch]);

  const date = new Date();

  const lastMonthDate = new Date(
    date.setFullYear(date.getUTCFullYear(), date.getMonth(), 0)
  ).toLocaleDateString();

  const endOfCurrentMonthDate = new Date(
    date.getFullYear(),
    date.getMonth() + 2,
    0
  ).toLocaleDateString();

  const fullName = `${user.firstName} ${user.lastName}`;
  const initials = `${user.firstName[0] || " "}${user.lastName[0] || " "}`
    .toString()
    .toUpperCase()
    .trim();

  const generatePositionTitle = (posArr) => {
    return posArr.reduce((previous, current, index) => {
      if (index > 0 && !previous.includes(current.position)) {
        previous += `${index < posArr.length - 1 ? "" : " & "}${current.position
          }`;
      }

      return previous;
    }, posArr[0]?.position || "");
  };

  const generateTeamTitle = (posArr) => {
    return posArr.reduce((previous, current, index) => {
      if (index > 0 && !previous.includes(current.position)) {
        previous += `${index < posArr.length - 1 ? "" : " & "}${current.position
          }`;
      }

      return previous;
    }, posArr[0]?.team?.name || "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSelectionChange = (index) => (event) => {
    const { name, value } = event.target;

    setSelections((selectedRows) =>
      selectedRows.map((el, selectionIndex) =>
        selectionIndex === index
          ? {
            ...el,
            [name]: value,
          }
          : el
      )
    );
    setDisabled(false);
  };

  const handleRemovingSelection = (selectionIndex) => {
    setSelections((list) =>
      list.filter((_selection, index) => index !== selectionIndex)
    );
  };

  const handleAddingSelection = () => {
    const limit = 2;
    if (selections.length < limit) {
      setSelections([...selections, { team: "", position: "" }]);
    } else {
      toast.warning(
        `c'est la limite! Juste ${limit} entrées sont acceptées à présent`
      );
    }
  };

  const updateUser = () => {
    birthdayDate.setHours(3, 0, 0);
    hiringDate.setHours(3, 0, 0);
    const data = {
      leaveBalance: parseFloat(userData.leaveBalance),
      lastMonthLeaveBalance: parseFloat(userData.lastMonthLeaveBalance),
      cellPhone: userData.cellPhone,
      user: user.id,
      birthdayDate: birthdayDate,
      hiringDate: hiringDate,
      positions: selections.map((selection) => parseInt(selection.position)),
    };
    setDisabled(true);
    dispatch(updateUserById(user.id, data));
  };

  const changeActiveStatus = () => {
    const updatedData = {
      isActive: !user.isActive
    };

    dispatch(updateUserById(user.id, updatedData))
      .then(() => {
        if (onUserUpdate) {
          onUserUpdate();
        }
      });
  };

  return (
    <div className="usercard_wrapper" style={{padding: roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH") ? '1.3rem 1.3rem 0.3rem' : '1.3rem'}}>
      <div className="avatarAndName">
        <div style={{fontSize: 22}} className="userAvatar">
          {!user.image ? (
            <Avatar
              label={initials}
              className="mr-3"
              size="xlarge"
              shape="circle"
              style={{fontSize: 25}}
            />
          ) : (
            <Avatar
              image={`${BASE_URL}${user.image?.fileUrl}`}
              className="mr-3"
              size="xlarge"
              shape="circle"
            />
          )}
          {(roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) && (
            <button
              className={`status-button ${user.isActive ? 'active' : 'inactive'}`}
              title={user.isActive ? "Active User" : "Inactive User"}
              data-bs-toggle="modal"
              data-bs-target={`#changeActiveStatus${user.id}`}
            >
              {user.isActive ? (
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512" fill="lightgreen">
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512" fill="red">
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                </svg>
              )}
            </button>
          )}
        </div>
        <div className="nameAndPosition mt-2">
          <div className="h3-Montserrat" style={{fontSize: 17}}>{fullName}</div>
          <div
            style={{
              fontWeight: "400",
              color: "#99999B",
              fontSize: 14,
              textAlign: "start",
            }}
            className="h6-Monstserrat"
          >
            {generatePositionTitle(user.positions)}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`changeActiveStatus${user.id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h6>Vous êtes s de vouloir modifier le statut d'activation de {user.firstName + " " + user.lastName} ?</h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={changeActiveStatus}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="generalInfo">
        <div className="infos-wrapper">
          {(roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) && (
            <>
              <div className="infoRow">
                <div className="cardTitle-Montserrat">Solde de congés</div>
                <div className="label-Montserrat">{user.leaveBalance}</div>
              </div>
              <div className="infoRow">
                <div className="cardTitle-Montserrat">
                  Solde de congés au&nbsp;
                  {endOfCurrentMonthDate}
                </div>
                <div className="label-Montserrat">
                  {user.endOfCurrentMonthLeaveBalance}
                </div>
              </div>
            </>
          )}
          <div className="infoRow">
            <div className="cardTitle-Montserrat">Email</div>
            <div className="label-Montserrat">{user.email}</div>
          </div>
          <div className="infoRow">
            <div className="cardTitle-Montserrat">Date de naissance</div>
            <div className="label-Montserrat">
              {dateHelpers.formatDateToFrenchFormat(
                user.birthdayDate.split("T")[0]
              )}
            </div>
          </div>
          <div className="infoRow">
            <div className="cardTitle-Montserrat">Date d'entrée</div>
            <div className="label-Montserrat">
              {dateHelpers.formatDateToFrenchFormat(
                user.hiringDate.split("T")[0]
              )}
            </div>
          </div>
          <div className="infoRow">
            <div className="cardTitle-Montserrat">Type de contrat</div>
            <div className="label-Montserrat">{user.contractType}</div>
          </div>
          <div className="infoRow">
            <div className="cardTitle-Montserrat">Equipe</div>
            <div className="label-Montserrat">
              {generateTeamTitle(user.positions)}
            </div>
          </div>
          {user.cellPhone && (
            <div className="infoRow iconified">
              <span className="btn-icon mr-1">
                <PhoneIphoneIcon style={{fontSize: "medium"}} />
              </span>
              <div className="label-Montserrat">{user.cellPhone}</div>
            </div>)
          }
        </div>
        {(roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) && (
          <div className="button-group">
            <button
              style={{
                width: 40,
                height: 40,
                borderRadius: 20
              }}
              type="button"
              className="btn btn-outline-primary"
              title="Modifier un profil"
              data-bs-toggle="modal"
              data-bs-target={`#staticBackdrop${user.id}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16">
                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
              </svg>
            </button>
            {user.isActive && (
              <button
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 20
                }}
                type="button"
                className="btn btn-outline-secondary"
                title="Ajouter un congé"
                data-bs-toggle="modal"
                data-bs-target={`#staticBackdrop${user.id}addLeave`}
              >
                +
              </button>
            )}
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id={`staticBackdrop${user.id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {user.firstName}&nbsp;{user.lastName}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {user.image?.fileUrl === undefined ? (
                <Avatar
                  label={initials}
                  className="mr-3"
                  size="xlarge"
                  shape="circle"
                  style={{fontSize: 25}}
                />
              ) : (
                <Avatar
                  image={`${BASE_URL}${user.image?.fileUrl}`}
                  className="mr-3"
                  size="xlarge"
                  shape="circle"
                />
              )}
              <div className="form">
                <div className="balance-container">
                  <label>Solde de congés</label>
                  <div>
                    <input
                      name="leaveBalance"
                      type="number"
                      step="0.01"
                      id="leaveBalance"
                      className="form-control"
                      placeholder="Solde de congés"
                      value={userData.leaveBalance}
                      onChange={(e) => {
                        handleChange(e);
                        setDisabled(false);
                      }}
                    />
                  </div>
                </div>
                <div className="balance-container">
                  <label>Solde au&nbsp; {endOfCurrentMonthDate}</label>
                  <div>
                    <input
                      name="endOfCurrentMonthLeaveBalance"
                      type="number"
                      step="0.01"
                      id="endOfCurrentMonthLeaveBalance"
                      className="form-control"
                      placeholder="Solde congé"
                      value={userData.endOfCurrentMonthLeaveBalance}
                      onChange={(e) => {
                        handleChange(e);
                        setDisabled(false);
                      }}
                    />
                  </div>
                </div>
                <div className="balance-container">
                  <label>Date de naissance</label>
                  <div>
                    <DatePicker
                      name="birthdayDate"
                      className="form-control"
                      closeOnScroll={(e) => e.target === document}
                      selected={birthdayDate}
                      dateFormat={"dd/MM/yyyy"}
                      locale={"fr"}
                      onChange={(date) => {
                        setBirthdayDate(date);
                        setDisabled(false);
                      }}
                    />
                  </div>
                </div>
                <div className="balance-container">
                  <label>Date d'entrée</label>
                  <div>
                    <DatePicker
                      className="form-control"
                      closeOnScroll={(e) => e.target === document}
                      selected={hiringDate}
                      dateFormat={"dd/MM/yyyy"}
                      locale={"fr"}
                      onChange={(date) => {
                        setHiringDate(date);
                        setDisabled(false);
                      }}
                    />
                  </div>
                </div>
                <div className="balance-container">
                  <label>Numéro de téléphone</label>
                  <div>
                    <input
                      name="cellPhone"
                      type="tel"
                      id="type"
                      className="form-control"
                      placeholder="Numéro de téléphone"
                      value={userData.cellPhone}
                      onChange={(e) => {
                        handleChange(e);
                        setDisabled(false);
                      }}
                    />
                  </div>
                </div>
                <div className="teamAndPosConfig mt-6">
                  {selections.map((selectedTeamPosition, index) => (
                    <TeamPositionDropdown
                      key={index}
                      index={index}
                      selectedTeamPosition={selectedTeamPosition}
                      selections={selections}
                      teams={teams}
                      handleInputChange={handleSelectionChange(index)}
                      handleSelectionRemove={handleRemovingSelection}
                    />
                  ))}
                </div>
                <button
                  type="button"
                  className="btn btn-outline-secondary mt-2"
                  style={{
                    width: '100%',
                    height: 40,
                  }}
                  onClick={() => handleAddingSelection()}
                >
                  + Ajouter une position
                </button>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="Button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => updateUser()}
                disabled={
                  userData.leaveBalance === "" ||
                  userData.lastMonthLeaveBalance === "" ||
                  disabled
                }
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`staticBackdrop${user.id}addLeave`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <AddLeaveForUserForm user={user} />
      </div>
    </div>
  );
};

UserCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    birthdayDate: PropTypes.string.isRequired,
    hiringDate: PropTypes.string.isRequired,
    leaveBalance: PropTypes.string.isRequired,
    lastMonthLeaveBalance: PropTypes.string.isRequired,
    cellPhone: PropTypes.string,
    image: PropTypes.shape({
      fileUrl: PropTypes.string,
    }),
    positions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        team: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
        position: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired, // Add this line
  onUserUpdate: PropTypes.func,
};

export default UserCard;
