import React, { useState, useCallback, useEffect } from 'react';
import "./teamPositionDropDown.sass";

const TeamPositionDropdown = ({ index, selectedTeamPosition, selections, teams, handleInputChange, handleSelectionRemove, ..._props }) => {
    const [positionOptions, setPositionOptions] = useState([]);

    const getPositionsOptions = useCallback((teamId) => {
        if (teamId === '') {
            return [];
        }

        const selectedTeam = teams?.find(team => team.id === parseInt(teamId));
        if (selectedTeam) {
            return selectedTeam.positions?.map(position => ({
                id: position.id,
                name: position.position,
            }));
        }

        return [];
    }, [teams])

    useEffect(() => {
        setPositionOptions(getPositionsOptions(selectedTeamPosition.team));
    }, [selectedTeamPosition, getPositionsOptions]);

    const updatePositionOptionsList = (event) => {
        handleInputChange(event);
        // on a new team selection we should empty the corresponding position
        handleInputChange({ target: { name: 'position', value: '' } })
        setPositionOptions(getPositionsOptions(event.target.value));
    }

    return (
        <div className="teamPosContainer">
            <div className="equipe">
                <h5 className="selecttitle">Équipe</h5>
                <select
                    required
                    id="team"
                    name="team"
                    className="form-select"
                    value={selectedTeamPosition.team}
                    onChange={e => updatePositionOptionsList(e)}
                >
                    <option key='' value="">Sélectionner une équipe</option>
                    {teams?.map(team => <option key={team.id} value={team.id}>
                        {team.name}
                    </option>)}
                </select>
            </div>
            <div className="poste">
                <h5 className="selecttitle">Poste</h5>
                <select
                    required
                    name="position"
                    id="position"
                    className="form-select"
                    value={selectedTeamPosition.position}
                    onChange={e => handleInputChange(e)}
                >
                    <option key="" value="">Sélectionner une poste</option>
                    {positionOptions?.map(position => (
                        <option key={position.id} value={position.id}>
                            {position.name}
                        </option>
                    ))}
                </select>
            </div>
            {selections.length !== 1 && (
                <div className="removebtn">
                    <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => handleSelectionRemove(index)}
                    >
                        X
                    </button>
                </div>
            )}
        </div>
    )
}

export default TeamPositionDropdown;
