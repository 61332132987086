import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../services/utils/apiRessources";
import { toast } from "react-toastify";

const initialState = {
  id: null,
  team: null,
  teamsList: [],
  positionsList: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    populateTeam: (state, action) => {
      state.id = action.payload.id;
      state.team = action.payload.team;
    },
    populateTeamsList: (state, action) => {
      state.teamsList = action.payload.teamsList;
    },
    populatePositionsList: (state, action) => {
      state.positionsList = action.payload.positionsList;
    },
    unselectPosition: (state, _action) => {
      state.id = null;
      state.team = null;
    },
    unselectTeamsList: (state, _action) => {
        state.teamsList = [];
    },
    unselectPositionsList: (state, _action) => {
      state.positionsList = [];
    },
    addItemToTeamsList: (state, action) => {
      state.teamsList.push(action.payload);
    },
    removeItemFromTeamsList: (state, action) => {
      const index = state.teamsList.findIndex(
        (item) => item.id === action.payload
      );

      if (index !== -1) {
        state.teamsList.splice(index, 1);
      }
    },
    addPositionToItem: (state, action) => {
      const teamIndex = state.teamsList.findIndex(teamItem => teamItem.id === action.payload.team.id);
      if (teamIndex !== -1) {
        // To keep data conformity
        delete action.payload['team'];
        delete action.payload['@context']
        state.teamsList[teamIndex].positions.push(action.payload);
      }
    },
    removePositionFromTeamsList: (state, action) => {
      const teamIndex = state.teamsList.findIndex(team => team.id === action.payload.teamId);
      if (teamIndex !== -1) {
        const positionIndex = state.teamsList[teamIndex].positions.findIndex(position => position.id === action.payload.id);
        if (positionIndex !== -1) {
          state.teamsList[teamIndex].positions.splice(positionIndex, 1)
        }
      }
    },
  },
});

export const fetchTeamsList = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/teams`)
    .then((res) => {
      dispatch(populateTeamsList({ teamsList: res.data["hydra:member"] }));
    })
    .catch((_err) => {
      toast.error(
        "Une erreur s'est produite lors de la récupération de la liste des équipes"
      );
    });
};

export const addTeam = (data) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/teams`, data)
    .then((res) => {
      toast.success("Vous avez ajouté une équipe avec succéss");
      dispatch(addItemToTeamsList(res.data));
    })
    .catch((_err) => {
      toast.error("Une erreur s'est produite lors de l'ajout d'une équipe");
    });
};

export const addPosition = (data) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/positions`, data)
    .then((res) => {
      toast.success("Vous avez ajouté une poste avec succéss");
      dispatch(addPositionToItem(res.data));
    })
    .catch((_err) => {
      toast.error("Une erreur s'est produite lors de l'ajout d'une poste");
    });
};

export const deleteTeam = (id) => (dispatch) => {
  axios
    .delete(`${BASE_URL}/api/teams/${id}`)
    .then((_res) => {
      toast.success("Vous avez supprimé une équipe avec succéss");
      dispatch(removeItemFromTeamsList(id));
    })
    .catch((_err) => {
      toast.error(
        "Une erreur s'est produite lors de la suppression d'une équipe"
      );
    });
};

export const deletePosition = (id, teamId) => (dispatch) => {
  axios
    .delete(`${BASE_URL}/api/positions/${id}`)
    .then((_res) => {
      toast.success("Vous avez supprimé une position avec succéss");
      dispatch(removePositionFromTeamsList({id, teamId}));
    })
    .catch((_err) => {
      toast.error(
        "Une erreur s'est produite lors de la suppression d'une position"
      );
    });
};

export const selectTeamsList = (state) => {
  return state.teams.teamsList;
};

export const selectPositionsList = (state) => {
  return state.teams.positionsList;
}

export const {
  populateTeam,
  populateTeamsList,
  populatePositionsList,
  unselectPosition,
  unselectTeamsList,
  unselectPositionsList,
  addItemToTeamsList,
  removeItemFromTeamsList,
  addPositionToItem,
  removePositionFromTeamsList,
} = teamsSlice.actions;

export default teamsSlice.reducer;
